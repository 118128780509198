import React, { useEffect, useState } from 'react';
import api from '../../utils/api'; // Import axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../../styles/adminLogin.css'; // Custom styles

const AdminLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading
    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Check if userId exists in localStorage and redirect to /admin-dashboard
    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (userId) {
            navigate('/admin-dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setErrorMessage('Both username and password are required');
            return;
        }

        setLoading(true); // Set loading to true

        try {
            const response = await api.post('/user/login', {
                username,
                password,
            }, { withCredentials: true }); // Include withCredentials to send cookies
            
            // Save user ID in localStorage
            const userId = response.data.result.id; // Extract user ID from the response
            localStorage.setItem('userId', userId); // Save user ID in localStorage
            
            // Navigate to the SideNavbar component or your desired route
            navigate('/admin-dashboard'); // Adjust the path to match your routing configuration
        } catch (error) {
            console.error('Login failed', error);
            setErrorMessage('Login failed. Please check your credentials.');
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="admin-login-container">
            <form className="admin-login-form" onSubmit={handleSubmit}>
                <h2 className="admin-login-title">Admin Login</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    placeholder="Enter your username"
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter your password"
                />
                <button type="submit" className="admin-login-btn" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>
        </div>
    );
};

export default AdminLogin;
