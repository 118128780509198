import React, { useState, useEffect, useRef } from "react";
import api from "../../utils/api";
import "../../styles/addProduct.css";
import ProductReview from "./productReview";
import Select from "react-select";

const AddProductForm = () => {
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(""); // Changed to store selected category ID
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [selectedCategory, setSelectedCategory] = useState(null);
  const fileInputRefs = useRef(null);

  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        try {
          await api.get(`/user/authenticate/${userId}`, {
            withCredentials: true,
          });
        } catch (error) {
          console.error("User authentication failed:", error);
          localStorage.removeItem("userId"); // Reset userId in localStorage
          window.location.href = "/admin"; // Redirect to admin if authentication fails
        }
        const response = await api.get("/category");
        setCategories(
          response.data.result.map((cat) => ({
            value: cat.id, // Store the category ID
            label: cat.categoryName, // Display the category name
          }))
        );
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index)); // Remove image by index
  };
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleCategoryChange = (selectedOption) => {
    setCategoryId(selectedOption.value); // Store the category ID
    setSelectedCategory(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Reset messages
    setErrorMessage("");
    let productCreated=false;
    let productId;
    try {
      await api.get(`/user/authenticate/${userId}`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
      return; // Prevent further code execution
    }

    try {
      // Ensure a category is selected
      if (!categoryId) {
        throw new Error("Please select a category.");
      }
  
      // Ensure images are uploaded
      if (images.length < 1) {
        throw new Error("Please upload at least one image.");
      }
      
      // Create the product
      const response = await api.post(
        `/product/new-product/${userId}`,
        {
          productName,
          description,
          categoryId,
        },
        { withCredentials: true }
      );

      productCreated=true;
  
       productId = response.data.result.id;
  
      // Upload images for the product
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("images", image);
      });
  
      await api.post(
        `/product/upload-productImage/${userId}/${productId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
  
      // Set success message and reset form
      setSuccessMessage("Product added successfully!");
      resetForm(); // Call resetForm function to reset the form
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage(
        `${
          error.response?.data?.details || error.message || "Failed to add product. Please try again."
        }`
      );
      if(productCreated){
        const url = `/product/delete-product/${userId}/${productId}`;
        api.delete(url, { withCredentials: true });      }
    }
  };
  

  const resetForm = () => {
    setProductName("");
    setDescription("");
    setImages([]);
    setCategoryId(""); // Reset category
    fileInputRefs.current.value = null;
    setSelectedCategory(null);
  };

  return (
    <div className="add-event-form-container">
      <div className="form-container">
        <div className="form-content">
          <form className="form-section" onSubmit={handleSubmit}>
            <h2>Add New Product</h2>

            <div className="form-group">
              <label>
                Product Name:
                <input
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  required
                  className="input-field"
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Description:
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="textarea-field"
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Image Upload:
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  multiple
                  ref={fileInputRefs}
                  className="add-event-image-input"
                />
              </label>
              {images.length > 0 && <h4>Selected Images:</h4>}
              {images.length > 0 && (
                <div className="image-previews">
                  {images.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Preview ${index}`}
                        className="image-preview-thumbnail"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="add-event-remove-image-button">
                        X
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="add-event-product-selection">
              <h3>Select Category:</h3>
              <Select
                options={categories}
                onChange={handleCategoryChange}
                required
                value={selectedCategory}
                className="add-event-product-dropdown"
              />
            </div>
            {/* Display Success and Error Messages */}
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>

        <div className="product-review-section">
          <h2>Product Review</h2>
          <ProductReview
            title={productName}
            category={
              categories.find((cat) => cat.value === categoryId)?.label ||
              "Select a category"
            } // Display category name or prompt
            description={description}
            images={images.map((file) => URL.createObjectURL(file))}
          />
        </div>
      </div>
    </div>
  );
};

export default AddProductForm;
