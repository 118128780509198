import React, { useState, useEffect } from "react";
import api from "../../utils/api"; // Import axios
import "../../styles/modifyProducts.css"; // Import custom styles for theming

const ModifyProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [editProduct, setEditProduct] = useState(null);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isTrendingChecked, setIsTrendingChecked] = useState(false);
  const [isFeaturedChecked, setIsFeaturedChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const handleRemoveImage = (index) => {
    setUploadedImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const fetchProducts = async () => {
    try {
      const response = await api.get("/product", {
        withCredentials: true,
      });
      setProducts(response.data.result);
      setFilteredProducts(response.data.result);
    } catch (error) {
      console.error("Error fetching products:", error);
      // Optionally set an error state to display a message to the user
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get("/category", {
        withCredentials: true,
      });
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Optionally set an error state to display a message to the user
    }
  };

  const filterByCategory = (category) => {
    setSelectedCategory(category);
  };

  const searchProducts = async (query) => {
    setSearchQuery(query);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imageUrls = files.map((file) => URL.createObjectURL(file));

    // Update the state to append new files
    setUploadedImageFiles((prevFiles) => [...prevFiles, ...files]);

    // Set the uploaded images for previewing
    setUploadedImage((prevImages) => [
      ...(prevImages || []), // Ensure this is an array
      ...imageUrls,
    ]);
  };

  const updateProduct = async () => {
    const userId = localStorage.getItem("userId");

    const url = `/product/update-product/${userId}/${editProduct.id}`;
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
    }
    try {
      // Update product details
      await api.put(
        url,
        {
          productName: editProduct.productName,
          description: editProduct.description,
          categoryId: editProduct.categoryId,
        },
        { withCredentials: true }
      );

      // Upload new images
      const formData = new FormData();
      uploadedImageFiles.forEach((image) => {
        formData.append("images", image);
      });
      try {
        await api.get(`/user/authenticate/${userId}`, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      await api.post(
        `/product/upload-productImage/${userId}/${editProduct.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );

      // Refresh product list
      await fetchProducts();

      // Reset states after updating
      setEditProduct(null);
      setUploadedImage([]);
      setUploadedImageFiles([]);
      setShowEditModal(false);
      setErrorMessage(""); // Reset error message
    } catch (error) {
      console.error("Error updating product:", error);
      // Optionally set an error state to display a message to the user
    }
  };

  const deleteProduct = async () => {
    const userId = localStorage.getItem("userId");
    const url = `/product/delete-product/${userId}/${deleteProductId}`;
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
    }
    try {
      await api.delete(url, { withCredentials: true });
      await fetchProducts(); // Refresh the product list after deletion
      setDeleteProductId(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting product:", error);
      // Optionally set an error state to display a message to the user
    }
  };
  const handleDeleteImage = async (imageId) => {
    const userId = localStorage.getItem("userId");
    const url = `/product/delete-productImage/${userId}/${editProduct.id}/${imageId}`;
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
    }
    try {
      await api.delete(url, { withCredentials: true });

      // Update the editProduct state to remove the deleted image
      setEditProduct((prevEditProduct) => ({
        ...prevEditProduct,
        images: prevEditProduct.images.filter((image) => image.id !== imageId), // Ensure you have the correct image ID here
      }));

      // Optionally, refresh the products to ensure everything is in sync
      await fetchProducts();
    } catch (error) {
      console.error("Error deleting image:", error);
      // Optionally set an error state to display a message to the user
    }
  };
  const toggleTrending = async () => {
    const userId = localStorage.getItem("userId");
    const url = editProduct.isTrending
      ? `/product/make-product-untrending/${userId}/${editProduct.id}`
      : `/product/make-product-trending/${userId}/${editProduct.id}`;
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
    }
    try {
      await api.put(url, { withCredentials: true });
      setEditProduct((prev) => {
        const updatedProduct = { ...prev, isTrending: !prev.isTrending };

        // Update filteredProducts using the updatedProduct
        setFilteredProducts((prevFilteredProducts) =>
          prevFilteredProducts.map((product) =>
            product.id === updatedProduct.id ? updatedProduct : product
          )
        );

        return updatedProduct;
      });
    } catch (error) {
      console.error("Error toggling trending status:", error);
      setErrorMessage(error.response.data.details);
    }
  };

  const toggleFeatured = async () => {
    const userId = localStorage.getItem("userId");
    const url = editProduct.isFeatured
      ? `/product/unfeature-product/${userId}/${editProduct.id}`
      : `/product/feature-product/${userId}/${editProduct.id}`;

    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
    } catch (error) {
      console.error("User authentication failed:", error);
      localStorage.removeItem("userId"); // Reset userId in localStorage
      window.location.href = "/admin"; // Redirect to admin if authentication fails
      return; // Stop execution if authentication fails
    }

    try {
      await api.put(url, { withCredentials: true });

      // Toggle the isFeatured status and update editProduct
      setEditProduct((prev) => {
        const updatedProduct = { ...prev, isFeatured: !prev.isFeatured };

        // Update filteredProducts using the updatedProduct
        setFilteredProducts((prevFilteredProducts) =>
          prevFilteredProducts.map((product) =>
            product.id === updatedProduct.id ? updatedProduct : product
          )
        );

        return updatedProduct;
      });
    } catch (error) {
      console.error("Error toggling featured status:", error);
      setErrorMessage(
        error.response?.data?.details || "Failed to update product."
      );
    }
  };

  useEffect(() => {
    let filtered = [...products]; // Start with the full list of products
    console.log(products);
    // Apply category filter (if any)
    if (selectedCategory != "All") {
      console.log("yessssss");
      filtered = filtered.filter(
        (product) => product.category.categoryName == selectedCategory
      );
    }
    console.log(filtered);
    // Apply search filter (if any)
    if (searchQuery.trim()) {
      filtered = filtered.filter(
        (product) =>
          product.productName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          product.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply trending filter (if checked)
    if (isTrendingChecked) {
      filtered = filtered.filter((product) => product.isTrending === true);
    }

    // Apply featured filter (if checked)
    if (isFeaturedChecked) {
      filtered = filtered.filter((product) => product.isFeatured === true);
    }

    // Set the filtered products based on the applied filters
    setFilteredProducts(filtered);
    console.log("Filteredddd" + filtered);
  }, [
    isTrendingChecked,
    isFeaturedChecked,
    searchQuery,
    selectedCategory,
    products,
  ]);

  return (
    <div className="admin-modify-products-container">
      <div className="filterAndSearch">
        <div className="search-container">
          <span>
            <strong>Search For Product: </strong>
          </span>
          <input
            type="text"
            className="admin-search-bar"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => searchProducts(e.target.value)} // Call searchProducts on change
          />
        </div>
        <div className="filter-container">
          <span>
            <strong>Filter By Category: </strong>
          </span>
          <select
            className="admin-category-filter"
            onChange={(e) => filterByCategory(e.target.value)}
            value={selectedCategory}>
            <option value="All">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.categoryName}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isTrendingChecked}
            onChange={() => setIsTrendingChecked(!isTrendingChecked)} // Corrected here
          />
          Show Trending Only
        </label>
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            checked={isFeaturedChecked}
            onChange={() => setIsFeaturedChecked(!isFeaturedChecked)} // Corrected here
          />
          Show Featured Only
        </label>
      </div>

      <div className="admin-product-list">
        {filteredProducts.map((item) => (
          <div key={item.id} className="admin-product-bar">
            <div className="admin-product-details">
              <img
                src={
                  item.images.length > 0
                    ? `${process.env.REACT_APP_IMAGE_URL}${item.images[0].imageUrl}`
                    : "/assets/imageNotFound.png"
                }
                alt={item.productName}
                className="admin-product-image"
              />

              <div className="admin-product-info">
                <h4>{item.productName}</h4>
                <h4>
                  <strong>Category:</strong>{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {item.category.categoryName}
                  </span>
                </h4>
                <p>
                  <strong>Description:</strong> {item.description}
                </p>
              </div>
            </div>
            <div className="admin-product-actions">
              <button
                className="admin-edit-btn"
                onClick={() => {
                  setUploadedImage(null);
                  setEditProduct({ ...item });
                  setShowEditModal(true); // Show the edit modal
                }}>
                Edit
              </button>
              <button
                className="admin-delete-btn"
                onClick={() => {
                  setDeleteProductId(item.id);
                  setShowDeleteModal(true); // Show the delete confirmation modal
                }}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Product Modal */}
      {showEditModal && (
        <div className="admin-modal">
          <div className="admin-modal-content">
            <h3>Edit Product</h3>
            <div className="modal-split-layout">
              {/* Left section: Product details */}
              <div className="product-details">
                <label>Name: </label>
                <input
                  type="text"
                  name="productName"
                  value={editProduct.productName}
                  onChange={handleEditChange}
                />
                <br />

                <label>Description: </label>
                <textarea
                  name="description"
                  value={editProduct.description}
                  onChange={handleEditChange}
                  rows={5}
                  style={{ resize: "vertical", width: "100%" }}
                />
                <br />

                <label>Category: </label>
                <select
                  name="categoryId"
                  value={editProduct.categoryId}
                  onChange={handleEditChange}
                  className="category-dropdown">
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
                <br />

                <div className="toggle-item">
                  <label>Is Trending:</label>
                  <button type="button" onClick={toggleTrending}>
                    {editProduct.isTrending ? "Unset Trending" : "Set Trending"}
                  </button>
                </div>
                <br />
                <div className="toggle-item">
                  <label>Is Featured:</label>
                  <button type="button" onClick={toggleFeatured}>
                    {editProduct.isFeatured ? "Unset Featured" : "Set Featured"}
                  </button>
                </div>

                <br />
                <label>Upload New Image: </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  className="add-event-image-input"
                  onChange={handleImageUpload}
                  ref={uploadedImage}
                />
                <br />
              </div>

              {/* Right section: Image slider */}
              <div className="product-images-slider">
                <h4>Current Product Images</h4>
                <div className="admin-image-slider">
                  {editProduct.images.map((img, index) => (
                    <div key={index} className="slider-item">
                      <img
  src={`${process.env.REACT_APP_IMAGE_URL}${img.imageUrl}`}
  alt={`Product ${index + 1}`}
  className="slider-image"
/>

                      {editProduct.images.length > 1 && (
                        <button
                          className="delete-image-btn"
                          onClick={() => handleDeleteImage(img.id)}>
                          Delete
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {uploadedImage && (
              <div>
                <label>Image Preview:</label>
                <br />
                <div className="image-preview-container">
                  {uploadedImage.map((url, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={url}
                        alt={`Preview ${index}`}
                        className="image-preview-thumbnail"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        className="add-event-remove-image-button">
                        X
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}{" "}
            {/* Display error message */}
            <div className="modal-actions">
              <button className="admin-save-btn" onClick={updateProduct}>
                Save
              </button>
              <button
                className="admin-cancel-btn"
                onClick={() => {
                  setShowEditModal(false);
                  setErrorMessage("");
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="admin-modal">
          <div className="admin-modal-content">
            <h3>Are you sure you want to delete this product?</h3>
            <p>**This action cannot be undone.</p>
            <div className="admin-modal-actions">
              <button
                className="admin-confirm-delete-btn"
                onClick={deleteProduct}>
                Yes
              </button>
              <button
                className="admin-cancel-delete-btn"
                onClick={() => setShowDeleteModal(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModifyProducts;
