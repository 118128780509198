import React, { useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../../styles/adminLogin.css'; // Custom styles

const AdminRegister = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // State to handle success messages
    const [loading, setLoading] = useState(false); // State to manage loading
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!username || !password || !confirmPassword) {
            setErrorMessage('All fields are required');
            setSuccessMessage(''); // Clear success message if validation fails
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            setSuccessMessage(''); // Clear success message if validation fails
            return;
        }

        setLoading(true); // Set loading to true
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            try {
                await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
              } catch (error) {
                console.error("User authentication failed:", error);
                localStorage.removeItem("userId"); // Reset userId in localStorage
                window.location.href = "/admin"; // Redirect to admin if authentication fails
              }
            const response = await api.post(`/user/register/${userId}`, {
                username,
                password,
                confirmPassword,
            }, { withCredentials: true }); // Include withCredentials to send cookies
            
            setSuccessMessage('Registration successful!'); // Set success message
            setErrorMessage(''); // Clear any previous error message

            // Reset form fields
            setUsername('');
            setPassword('');
            setConfirmPassword('');

        } catch (error) {
            console.error('Registration failed', error);
            // Check if the error response contains specific error details
            if (error.response && error.response.data && error.response.data.details) {
                setErrorMessage(error.response.data.details); // Set the specific error message
            } else {
                setErrorMessage('Registration failed. Please try again.');
            }
            setSuccessMessage(''); // Clear success message on error
        }
         finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="admin-login-container">
            <form className="admin-login-form" onSubmit={handleSubmit}>
                <h2 className="admin-login-title">Admin Register</h2>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    placeholder="Enter your username"
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter your password"
                />
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    placeholder="Confirm your password"
                />
                
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                <button type="submit" className="admin-login-btn" disabled={loading}>
                    {loading ? 'Registering...' : 'Register'}
                </button>
            </form>
        </div>
    );
};

export default AdminRegister;
