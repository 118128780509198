import React, { useEffect, useState, useRef } from 'react';
import api from '../../utils/api';
import Product from '../shared/product'; // Adjust the path if necessary
import '../../styles/trendingProducts.css';

const CategoryCards = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); // State to store categories
  const [isLeftDisabled, setIsLeftDisabled] = useState(true); // Left arrow initially disabled
  const [isRightDisabled, setIsRightDisabled] = useState(false); // Right arrow initially enabled
  const [categoryId, setCategoryId] = useState(null); // Change to store category ID
  const [loading, setLoading] = useState(false); // State for loading
  const cardRef = useRef(null); // Reference for the card container

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); // Start loading
      try {
        const response = await api.get('/category'); // Fetch categories from API
        const categoryData = response.data.result || []; // Ensure it's an array

        // Filter categories that have trending products
        const categoriesWithProducts = [];

        for (const category of categoryData) {
          const productResponse = await api.get(`/product/trendingproducts/${category.id}`);
          if (productResponse.data.result.length > 0) {
            categoriesWithProducts.push(category);
          }
        }
        if(categoriesWithProducts.length > 0) {
           // Shuffle categories and select first 6
        const shuffledCategories = categoriesWithProducts.sort(() => 0.5 - Math.random());
        const selectedCategories = shuffledCategories.slice(0, 6); // Limit to 6 categories
        setCategories(selectedCategories);
        
        }
        else{
          setCategories(categoriesWithProducts);
        }
       

         // Set categories that have products
        if (categoriesWithProducts.length > 0) {
          setCategoryId(categoriesWithProducts[0].id); // Set default category ID to the first one
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchCategories();
  }, []); // Fetch categories only on mount

  // Fetch products based on the selected category
  useEffect(() => {
    const fetchProducts = async () => {
      if (categoryId) {
        setLoading(true); // Start loading
        try {
          const response = await api.get(`/product/trendingproducts/${categoryId}`);
          setProducts(response.data.result);
          requestAnimationFrame(updateArrowStates); // Ensure the layout is settled
        } catch (error) {
          console.error('Error fetching products:', error);
        } finally {
          setLoading(false); // End loading
        }
      }
    };

    fetchProducts();
  }, [categoryId]); // Add categoryId as a dependency

  // Ensure arrow states update after rendering products and DOM is fully settled
  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateArrowStates); // Ensure updateArrowStates runs after layout is updated
    };

    if (products.length > 0) {
      requestAnimationFrame(() => {
        updateArrowStates();
      }); // Ensure arrow states update after products are fully rendered
    }

    // Add event listener for window resize to handle arrow states
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [products]);

  // Function to scroll left
// Function to scroll left based on product width
const scrollLeft = () => {
  if (cardRef.current) {
    const productCard = cardRef.current.querySelector('.trending-product-width');
    const cardWidth = productCard ? productCard.offsetWidth : 300; // Fallback to 300 if card width cannot be determined
    cardRef.current.scrollBy({ left: -cardWidth, behavior: 'smooth' });
    setTimeout(updateArrowStates, 300); // Update after scrolling
  }
};

// Function to scroll right based on product width
const scrollRight = () => {
  if (cardRef.current) {
    const productCard = cardRef.current.querySelector('.trending-product-width');
    const cardWidth = productCard ? productCard.offsetWidth : 300; // Fallback to 300 if card width cannot be determined
    cardRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' });
    setTimeout(updateArrowStates, 300); // Update after scrolling
  }
};


  // Function to update arrow states based on scroll position
  const updateArrowStates = () => {
    if (cardRef.current) {
      const scrollLeft = cardRef.current.scrollLeft;
      const scrollWidth = cardRef.current.scrollWidth;
      const clientWidth = cardRef.current.clientWidth;

      // Disable left arrow if at the start
      setIsLeftDisabled(scrollLeft === 0);

      if (clientWidth === scrollWidth) {
        setIsRightDisabled(false);
        return;
      }
      // Disable right arrow if at the end, with a small tolerance
      setIsRightDisabled(scrollLeft + clientWidth > scrollWidth - 5); // Added small tolerance of 5px
    }
  };

  return (
    <div className='trending-container'>
      <h2 className='trending-title'>Trending Products</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {loading ? ( // Loading state
          <div className="loading">Loading categories...</div> // Loading message for categories
        ) : (
          categories.map((category) => (
            <button
              key={category.id} // Assuming each category has a unique id
              className={`trending-button ${category.id === categoryId ? 'active' : ''}`} 
              onClick={() => setCategoryId(category.id)} // Use category ID
            >
              {category.categoryName} {/* Use category name here */}
            </button>
          ))
        )}
      </div>
      <div className="product-slider">
        <button className="trending-arrow-button left-arrow" onClick={scrollLeft} disabled={isLeftDisabled}>
          &#8249; {/* Left arrow character */}
        </button>
        <div className="product-cards" ref={cardRef} onScroll={updateArrowStates}>
          {loading ? ( // Loading state for products
            <div className="loading">Loading products...</div> // Replace with your loading component or styling
          ) : (
            products.length > 0 ? (
              products.map((product) => (
                <div className="trending-product-width" key={product.id}>
                  <Product 
                    product={product}
                    path={product.images} // Extract first image and format path
                    title={product.productName} 
                    description={product.description} // Pass the long description
                    category={product.category.categoryName} // Pass the category name
                    name={product.productName} // Pass the product name
                  />
                </div>  
              ))
            ) : (
              <div>No products available</div>
            )
          )}
        </div>
        <button className="trending-arrow-button right-arrow" onClick={scrollRight} disabled={isRightDisabled}>
          &#8250; {/* Right arrow character */}
        </button>
      </div>
    </div>
  );
};

export default CategoryCards;
