import React, { useState } from 'react';
import '../../styles/popup.css'; // Import CSS for popup styling

const AdminPopup = ({ isVisible, onClose, product }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for the current image index
  
  const {description } = product;
  const images = product.images;
  // const images = product.images.map(image => {
  //   // Split, slice, and join the imageUrl
  //   const updatedUrl = image.imageUrl.split('\\').slice(-2).join('/');
   
  //   // Return a new object with the updated imageUrl
  //   return {
  //     ...image,  // Preserve other properties
  //     imageUrl: updatedUrl  // Update the imageUrl
  //   };
  // });

  const title=product.productName;
  const productName=product.productName;
  const category=product.category.categoryName;
  const handleRequestPriceClick = () => {
    setFormVisible(true);
  };

  const handleFormSubmit = (formData) => {

    
    setFormVisible(false);
    setConfirmationVisible(true);
    onClose(); // Close the product popup after confirmation
    setTimeout(() => {
      setConfirmationVisible(false);    
    }, 5000); // Hide confirmation after 5 seconds
  };

  // Function to handle next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (!isVisible) return null; // Don't render anything if not visible

  return (
    <div className="overlay">
      <div className="popup">
        <span className="close" onClick={onClose}>&times;</span>
        
        {/* Image Slider */}
        <div className="image-slider">
          <button className="slider-button prev" onClick={prevImage}>❮</button>
          <img 
  src={`${process.env.REACT_APP_IMAGE_URL}${images[currentImageIndex].imageUrl}`} 
  alt={productName} 
  className="popup-image" 
/>

          <button className="slider-button next" onClick={nextImage}>❯</button>
        </div>

        <div className="details-container">
          <h2 className="popup-title">{productName}</h2>
          <h4 className="popup-category">Category: {category}</h4>
          <hr className="divider" />
          <p className="popup-description">{description}</p>
        </div>
      </div>


    </div>
  );
};

export default AdminPopup;
