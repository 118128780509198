import React, { useState } from 'react';
import '../../styles/product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'; // Importing the eye icon
import Popup from '../shared/popup'; // Import the Popup component
import RequestPriceForm from './requestPrice'; // Import the new form component

const Product = ({ product, path, title, category, description }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false); // State for form visibility
  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const handleFormSubmit = (formData) => {
    setConfirmationVisible(true);
    closePopup(); // Close the product popup immediately
    setTimeout(() => {
      setConfirmationVisible(false);
    }, 5000); // Hide confirmation after 5 seconds
  };

  const handleIconClick = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setIsFormVisible(false); // Close form when the popup is closed
  };

  const handleRequestPriceClick = () => {
    setIsFormVisible(true); // Show the form
  };

  return (
    <div className="product">
      <div className="product-container">
        {path && path.length > 0 ? ( // Ensure that path has valid images
          <img 
          src={`${process.env.REACT_APP_IMAGE_URL}${path[0].imageUrl}`} // Use environment variable for image URL
          alt={title} 
          className='productIcon' 
        />
        
        ) : (
          <div style={{ margin: "auto" }}>Not found</div>
        )}
        {/* <div className="eye-icon" onClick={handleIconClick}>
          <FontAwesomeIcon icon={faEye} size="md" />
        </div> */}
        <div className="product-title">
          <p className="product-title">{title.toUpperCase()}</p>
        </div>
        <div className="view-more" onClick={handleIconClick}>
          View More
        </div>
        <button className="priceButton" onClick={handleRequestPriceClick}>Request Price</button>
      </div>

      {/* Show the Popup if it is visible */}
      {isPopupVisible && (
        <Popup
          isVisible={isPopupVisible}
          onClose={closePopup}
          product={{
            productId: product.id,
            imagePath: path,
            title: title,
            category: product.category.categoryName,
            description: description,
          }}
        />
      )}

      {/* Show the Request Price Form if it is visible */}
      {isFormVisible && (
        <div className="form-overlay">
          <RequestPriceForm
            productId={product.id} // Corrected the productId prop
            onClose={() => setIsFormVisible(false)}
            onSubmit={(formData) => {
              handleFormSubmit(formData);
            }}
          />
        </div>
      )}
        {confirmationVisible && (
        <div className="confirmation-message">
          We will get back to you soon!
        </div>
      )}
    </div>
  );
};

export default Product;
