import React from 'react';
import Navbar from '../shared/navbar'; // Import Navbar
import Footer from '../shared/footer';
import Shop from './shop';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Shop />
      <Footer />
    </div>
  );
}

export default App;
