import React, { useEffect, useState, useRef } from "react";
import api from "../../utils/api";
import Card from "../shared/card"; // Adjust the path as necessary
import "../../styles/categoriesSlider.css";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

const CategoryCards = () => {
  const [categories, setCategories] = useState([]);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const cardRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get("/category"); // Fetch categories from the API
        if (response.data && response.data.result) {
          setCategories(response.data.result);
          requestAnimationFrame(updateArrowStates); // Ensure initial arrow state
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(updateArrowStates);
    };

    if (categories.length > 0) {
      requestAnimationFrame(updateArrowStates);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [categories]);

  const scrollLeft = () => {
    if (cardRef.current) {
      const scrollAmount = window.innerWidth < 600 ? 180 : 250;
      cardRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      setTimeout(updateArrowStates, 300);
    }
  };

  const scrollRight = () => {
    if (cardRef.current) {
      const scrollAmount = window.innerWidth < 600 ? 180 : 250;
      cardRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      setTimeout(updateArrowStates, 300);
    }
  };

  const updateArrowStates = () => {
    if (cardRef.current) {
      const scrollLeft = cardRef.current.scrollLeft;
      const scrollWidth = cardRef.current.scrollWidth;
      const clientWidth = cardRef.current.clientWidth;

      // Check if we have scrolled to the left or right end
      const isAtStart = scrollLeft <= 0;
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth - 1; // Allow for small rounding errors

      // Update button states based on scroll position
      setIsLeftDisabled(isAtStart);
      setIsRightDisabled(isAtEnd);
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Explore our extensive selection of categories and discover your perfect product. Shop now at Mounirto for an easy and fast shopping experience."
        />
        <meta
          name="keywords"
          content="shop categories, online shopping, product categories, Mounirto"
        />
        <meta name="author" content="Mounirto" />
      </Helmet>
      <div className="category-slider">
        <button
          className="arrow-button left-arrow"
          onClick={scrollLeft}
          disabled={isLeftDisabled}>
          &#8249;
        </button>
        <div
          className="category-cards"
          ref={cardRef}
          onScroll={updateArrowStates}
          style={{ cursor: "pointer" }}>
          {categories.map((category, index) => (
            <Card
            key={index}
            path={`${process.env.REACT_APP_IMAGE_URL}${category.icon}`}
            title={category.categoryName}
            onClick={() => navigate(`/shop/${category.id}`)}
          />
          
          ))}
        </div>
        <button
          className="arrow-button right-arrow"
          onClick={scrollRight}
          disabled={isRightDisabled}>
          &#8250;
        </button>
      </div>
    </>
  );
};

export default CategoryCards;
