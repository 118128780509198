import React from 'react';

export const ImageRender = ({ slide }) => {
    const styleImg = {
        backgroundImage: `url(${slide.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', // Ensures the image covers the container
        width: '80vw',
        margin: 'auto',
        opacity: '1',
        height: '100vh',
        zIndex: 1,
        position: 'relative',
    };
    

    const overlayStyle = {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        zIndex: 0,
    };

    const contentStyle = {
        position: 'absolute',
        width: '100%',
        top: '20%',
        left: '0',
        zIndex: 2,
        textAlign: 'center',
    };

    const text1Style = {
        color: 'white',
        fontSize: '25px',
        fontFamily: 'Playfair Display, Roboto, sans-serif', // Use commas to separate font families
        fontStyle: 'italic',
        fontWeight: 400,
      };
      

      const text2Style = {
        fontSize: '3rem', // Adjust font size for larger screens
        fontWeight: 700, // Corrected to camel case
        fontFamily: 'Playfair Display, Montserrat, sans-serif', // Use commas to separate font families
      };
      

    const text3Style = {
        fontSize: '25px',
        color: 'white',

    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        margin: '40px 0',
    };

    const buttonStyle = {
        backgroundColor: '#219AB3',
        color: 'white',
        borderRadius: '9999px',
        padding: '8px 20px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    };

    const buttonOutlineStyle = {
        ...buttonStyle,
        backgroundColor: 'transparent',
        border: '1px solid gray',
    };

    return (
        <div style={styleImg} className='fade'>
            <style>
                {`
                    @keyframes fade {
                        from { opacity: .4; }
                        to { opacity: 1; }
                    }
                    .fade {
                        animation-name: fade;
                        animation-duration: 1.5s;
                    }
                    @keyframes updown {
                        0% { top: 0%; }
                        100% { top: 0px; }
                    }
                    @keyframes updown2 {
                        0% { top: 0%; }
                        100% { top: 25px; }
                    }
                    @keyframes updown3 {
                        0% { top: 0%; }
                        100% { top: 100px; }
                    }
                    @keyframes updown4 {
                        0% { top: 0%; }
                        100% { top: 140px; }
                    }
                `}
            </style>
            <div style={overlayStyle} />
            <div style={contentStyle}>
                <h1 style={text1Style}>{slide.p}</h1>
                <p style={text2Style}>{slide.h1}</p>
                <p style={text3Style}>{slide.p2}</p>
            </div>
        </div>
    );
};
