import React from "react";
import "../../styles/ContactUsForm.css"; // Assuming you save the styles in a separate file
import Navbar from "./navbar"; // Adjust the path as necessary
import Footer from "./footer"; // Adjust the path as necessary

const ContactForm = () => {
  return (
    <div>
      <Navbar />
      <div className="contact-container">
        {/* Left Side with Google Map and Contact Info */}
        <div className="contact-side">
          <div className="contact-google-map">
            <h3>Vist Us in Our Store</h3>
            {/* Embed Google Map here */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3775.100007887815!2d31.370621507186815!3d30.10131772103195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458166b4beae6b9%3A0x809f91f73e4f1633!2sMounirto!5e0!3m2!1sen!2seg!4v1729289850253!5m2!1sen!2seg"
              width="100%"
              height="70%"
              style={{ border: 0, minHeight: "70%", height: "85%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map Location"
            />
          </div>
        </div>

        {/* Right Side with Form Fields */}
        <div className="contact-side">
          <div className="contact-info">
            <h3>Call us:</h3>
            <p>
              <a
                href="tel:+201220647111"
                style={{ textDecoration: "none", color: "inherit" }}>
                +201220647111
              </a>
            </p>
          </div>
          {/* 

          <form className="contact-form">
            <h2 className="contact-form-title">Get in Touch</h2>

            <label htmlFor="name">Name</label>
            <input
              className="contact-input"
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              required
            />

            <label htmlFor="email">Email</label>
            <input
              className="contact-input"
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />

            <label htmlFor="phone">Phone Number</label>
            <input
              className="contact-input"
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
            />

            <label htmlFor="inquiry">Inquiry</label>
            <textarea
              className="contact-textarea"
              id="inquiry"
              name="inquiry"
              placeholder="Enter your message"
              rows="5"
              required></textarea>

            <button className="contact-submit-button" type="submit">
              Submit
            </button>
          </form> 
          */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactForm;
