import React, { useState } from 'react';
import '../../styles/popup.css'; // Import CSS for popup styling
import RequestPriceForm from '../shared/requestPrice'; // Import the new form component

const Popup = ({ isVisible, onClose, product }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for the current image index

  const { images, title, category, description } = product;

  const handleRequestPriceClick = () => {
    setFormVisible(true);
  };

  const handleFormSubmit = (formData) => {
    // Handle form submission logic (e.g., send data to an API)
    setFormVisible(false);
    setConfirmationVisible(true);
    onClose(); // Close the product popup after confirmation
    setTimeout(() => {
      setConfirmationVisible(false);    
    }, 5000); // Hide confirmation after 5 seconds
  };

  // Function to handle next image
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to handle previous image
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (!isVisible) return null; // Don't render anything if not visible

  return (
    <div className="overlay">
      <div className="popup">
        <span className="close" onClick={onClose}>&times;</span>
        
        {/* Image Slider */}
        <div className="image-slider">
          <button className="slider-button prev" onClick={prevImage}>❮</button>
          <img 
            src={images[currentImageIndex]} // Use current image from imagePath
            alt={title}
            className="popup-image" 
          />
          <button className="slider-button next" onClick={nextImage}>❯</button>
        </div>

        <div className="details-container">
          <h2 className="popup-title">{title}</h2>
          <h4 className="popup-category">Category: {category}</h4>
          <hr className="divider" />
          <p className="popup-description">{description}</p>
          <button className="request-price-button" onClick={handleRequestPriceClick}>
            Request Price
          </button>
        </div>
      </div>

      {/* Form Popup Overlay */}
      {formVisible && (
        <div className="form-overlay">
          <RequestPriceForm onClose={() => setFormVisible(false)} onSubmit={handleFormSubmit} />
        </div>
      )}

      {/* Confirmation Message */}
      {confirmationVisible && (
        <div className="confirmation-message">We will get back to you soon!</div>
      )}
    </div>
  );
};

export default Popup;
