import React from "react";
import "../../styles/AboutUsFrom.css"; // Assuming you save the styles in a separate file
import Navbar from "./navbar"; // Adjust the path as necessary
import Footer from "./footer"; // Adjust the path as necessary

const ContactForm = () => {
  return (
    <div>
      <Navbar />
      <div className="about-container">
        {/* Left Side with Google Map and Contact Info */}
        <div className="about-side">
          <div className="about-us-container">
            <h3>Mounirto Jewelry: Elegance You can Treasure Forever</h3>
            <p>
              Founded in 1950 by Sheikh Mounir, Mounirto Jewelry Co. has been a
              shining symbol of craftsmanship and innovation in the jewelry
              industry. Sheikh Mounir, a visionary whose title reflects his
              expertise in quality and advanced jewelry-making techniques, laid
              the foundation for a brand dedicated to excellence and timeless
              artistry.
              </p>
              <p>
              What began as a humble workshop in Cairo quickly gained
              recognition for its unmatched quality and intricate designs,
              establishing Mounirto Jewelry as a name synonymous with luxury and
              elegance. Over the years, the company expanded its reach to El
              Gouna, becoming a distinctive brand known for blending tradition
              with modernity.
              </p>
              <p>
              At Mounirto, we believe jewelry is more than adornment—it is an
              expression of individuality and a celebration of life's most
              meaningful moments. Guided by Sheikh Mounir's enduring legacy, our
              family-owned business continues to pass down the art of
              jewelry-making through generations, preserving the same passion
              for detail and dedication to quality that first defined our
              journey.
              </p>
              <p>
              From classic designs that honor our rich heritage to innovative
              creations that capture contemporary beauty, Mounirto Jewelry Co.
              remains committed to crafting pieces that inspire and endure. For
              over seven decades, we have been dedicated to transforming
              precious metals and gemstones into works of art, ensuring every
              piece tells a story of elegance, sophistication, and tradition.
              </p>
              <p>
              Today, we proudly serve a diverse clientele who seek jewelry that
              is not only exquisite but also deeply personal. With a strong
              presence in El Gouna and an ever-growing legacy, Mounirto Jewelry
              Co. continues to set new standards in the world of fine jewelry,
              one masterpiece at a time.
              </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactForm;
