import React, { useState } from 'react';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import '../../styles/adminLogin.css'; // Using the same CSS file

const PasswordUpdate = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!oldPassword || !newPassword || !confirmNewPassword) {
            setErrorMessage('All fields are required');
            setSuccessMessage('');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setErrorMessage('New passwords do not match');
            setSuccessMessage('');
            return;
        }

        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            try {
                await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
              } catch (error) {
                console.error("User authentication failed:", error);
                localStorage.removeItem("userId"); // Reset userId in localStorage
                window.location.href = "/admin"; // Redirect to admin if authentication fails
              }
            const response = await api.put(`/user/update/${userId}`, {
                oldPassword,
                newPassword,
                confirmNewPassword,
            }, { withCredentials: true }); // Send with credentials

            setSuccessMessage('Password updated successfully!');
            setErrorMessage('');

            // Reset form fields
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');

        } catch (error) {
            console.error('Password update failed', error);
            if (error.response && error.response.data && error.response.data.details) {
                setErrorMessage(error.response.data.details);
            } else {
                setErrorMessage('Password update failed. Please try again.');
            }
            setSuccessMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="admin-login-container">
            <form className="admin-login-form" onSubmit={handleSubmit}>
                <h2 className="admin-login-title">Update Password</h2>
                <label htmlFor="oldPassword">Old Password</label>
                <input
                    type="password"
                    id="oldPassword"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    placeholder="Enter your old password"
                />
                <label htmlFor="newPassword">New Password</label>
                <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    placeholder="Enter your new password"
                />
                <label htmlFor="confirmNewPassword">Confirm New Password</label>
                <input
                    type="password"
                    id="confirmNewPassword"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                    placeholder="Confirm your new password"
                />

                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                <button type="submit" className="admin-login-btn" disabled={loading}>
                    {loading ? 'Updating...' : 'Update Password'}
                </button>
            </form>
        </div>
    );
};

export default PasswordUpdate;
