// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/styles.css';
import "@fontsource/playfair-display/400-italic.css";
import "@fontsource/playfair-display/700.css";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
  <App />
</HelmetProvider>,
);
