import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/api';
import Select from 'react-select';
import '../../styles/AddEventForm.css'; // Adjust the path as necessary

const CategoryForm = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategoriesToDelete, setSelectedCategoriesToDelete] = useState([]);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const userId = localStorage.getItem('userId');

  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      const response = await api.get("/category", { withCredentials: true });
      const categoryOptions = response.data.result.map((cat) => ({
        value: cat.id,
        label: cat.categoryName,
      }));
      setCategories(categoryOptions);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setErrorMessage("Error fetching categories. Please try again later.");
    }
  };
  const handleDeleteCategories = async (e) => {
    e.preventDefault();
    setDeleteSuccessMessage('');
    setDeleteErrorMessage('');

    if (selectedCategoriesToDelete.length === 0) {
      setDeleteErrorMessage("Please select at least one category to delete.");
      return;
    }

    const remainingCategories = [...selectedCategoriesToDelete];
    try {
      await Promise.all(selectedCategoriesToDelete.map(async (cat) => {
        await api.delete(`/category/delete-category/${userId}/${cat.value}`, { withCredentials: true });
        remainingCategories.splice(remainingCategories.findIndex(c => c.value === cat.value), 1);
      }));

      setDeleteSuccessMessage('Selected categories deleted successfully!');
      setSelectedCategoriesToDelete([]);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting categories:", error);
      setDeleteErrorMessage(error.response?.data?.details || 'An unexpected error occurred.');
      setSelectedCategoriesToDelete(remainingCategories); // Reset to remaining categories
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategoriesToDelete(selectedOptions || []);
  };

  return (
    <div className="modify-event-form-container">
      {/* Delete Categories Form */}
      <form onSubmit={handleDeleteCategories} className="modify-event-form delete-category-form">
        <h2 className="modify-event-form-title">Delete Categories</h2>

        <div className="modify-event-product-selection">
          <h3>Select Categories to Delete:</h3>
          <Select
            isMulti
            options={categories}
            value={selectedCategoriesToDelete}
            onChange={handleCategoryChange}
            placeholder="Select categories to delete..."
            className="modify-event-select"
          />
        </div>

        {deleteSuccessMessage && <div className="success-message">{deleteSuccessMessage}</div>}
        {deleteErrorMessage && <div className="error-message">{deleteErrorMessage}</div>}
        
        <button type="submit" className="modify-event-submit-button">Delete Selected Categories</button>
      </form>
    </div>
  );
};

export default CategoryForm;
