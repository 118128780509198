import React, { useState } from 'react';
import '../../styles/product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'; 
import Popup from './popUpReview'; 
import RequestPriceForm from '../shared/requestPrice'; 

const ProductReview = ({ images, title, category, description }) => {
  
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false); 

  const handleIconClick = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setIsFormVisible(false); 
  };

  const handleRequestPriceClick = () => {
    setIsFormVisible(true); 
  };

  return (
    <div className="product">
      <div className="product-container">
        {images && images.length > 0 ? (
          <img src={images[0]} alt={title} className='productIcon' />
        ) : (
          <div style={{ margin: "auto" }}>No image available</div>
        )}
        <div className="eye-icon" onClick={handleIconClick}>
          <FontAwesomeIcon icon={faEye} size="md" />
        </div>
        <div className="product-title">
          <p className="product-title">{title.toUpperCase()}</p>
        </div>
        <button className="priceButton" onClick={handleRequestPriceClick}>Request Price</button>
      </div>

      {/* Show the Popup if it is visible */}
      <Popup
        isVisible={isPopupVisible}
        onClose={closePopup}
        product={{
          images,
          title,
          category,
          description,
        }}
      />

      {/* Show the Request Price Form if it is visible */}
      {isFormVisible && (
        <div className="form-overlay">
          <RequestPriceForm 
            onClose={() => setIsFormVisible(false)}  
            onSubmit={(formData) => {
              closePopup(); 
            }} 
          />
        </div>
      )}
    </div>
  );
};

export default ProductReview;
