import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import "../../styles/modifyProducts.css";

const ModifyProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [categories, setCategories] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [products, selectedCategory, searchQuery, sortOrder]);

  const fetchProducts = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get(`/product/highdemandproducts/${userId}`, {
        withCredentials: true,
      });
      setProducts(response.data.result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get("/category", { withCredentials: true });
      setCategories(response.data.result);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const applyFiltersAndSearch = () => {
    let filtered = [...products];

    // Filter by category
    if (selectedCategory !== "All") {
      filtered = filtered.filter(
        (product) => product.category.categoryName === selectedCategory
      );
    }

    // Search by product name or description
    if (searchQuery.trim()) {
      filtered = filtered.filter(
        (product) =>
          product.productName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          product.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort by request count
    if (sortOrder !== "none") {
      filtered = filtered.sort((a, b) => {
        return sortOrder === "asc"
          ? a.requestCount - b.requestCount
          : b.requestCount - a.requestCount;
      });
    }

    setFilteredProducts(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  return (
    <div className="admin-modify-products-container">
      <div className="filterAndSearch">
        <div className="search-container">
          <span>
            <strong>Search For Product: </strong>
          </span>
          <input
            type="text"
            className="admin-search-bar"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="filter-container">
          <span>
            <strong>Filter By Category: </strong>
          </span>
          <select
            className="admin-category-filter"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            <option value="All">All Categories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.categoryName}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div className="filter-container">
          <span>
            <strong>Sort By Request Count: </strong>
          </span>
          <select
            className="admin-category-filter"
            onChange={handleSortChange}
            value={sortOrder}
          >
            <option value="none">None</option>
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </select>
        </div>
      </div>
      <div className="admin-product-list">
        {filteredProducts.map((item) => (
          <div key={item.id} className="admin-product-bar">
            <div className="admin-product-details">
            <img
  src={
    item.images.length > 0
      ? `${process.env.REACT_APP_IMAGE_URL}${item.images[0].imageUrl}`
      : "/assets/imageNotFound.png"
  }
  alt={item.productName}
  className="admin-product-image"
/>

              <div className="admin-product-info">
                <h4>{item.productName}</h4>
                <h4>
                  <strong>Category:</strong>{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {item.category.categoryName}
                  </span>
                </h4>
                <p>
                  <strong>Description:</strong> {item.description}
                </p>
                <p>
                  <strong>Request Count:</strong> {item.requestCount}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModifyProducts;
