import React from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet
import Navbar from "../shared/navbar"; // Import Navbar
import Slider from "./slider";
import Footer from "../shared/footer";
import Events from "../shared/promotions";
import Categories from "./categories";
import NewCollection from "./newCollection";
import Products from "./trendingProducts"; // Import Product component
function App() {
  return (
    <div className="App">
      {/* Helmet for setting the document head */}
      <Helmet>
        <title>Mounirto - Elegance you can Treasure Forever</title>
        <meta
          name="description"
          content="Explore the finest collection of luxury jewelry at Mounirto. Discover elegant rings, necklaces, bracelets, and more."
        />
        <meta
          name="keywords"
          content="Mounirto jewellery,luxury jewelry, fine jewelry, rings, necklaces, bracelets, Mounirto, high-end accessories, elegant jewelry, jewelry collection"
        />
      </Helmet>

      <Navbar />
      <Slider />
      <Categories />
      <NewCollection />
      <Products />
      <Footer />
    </div>
  );
}

export default App;
