import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import AdminPopup from "./adminPopup"; // Import your Popup component
import "../../styles/adminProductRequests.css"; // Adjust the path if necessary
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const AdminProductRequests = () => {
  const [requests, setRequests] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newNotes, setNewNotes] = useState("");
  const [newStatus, setNewStatus] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false); // State for popup visibility
  const [selectedProduct, setSelectedProduct] = useState(null); // State for the selected product
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(""); // State for selected filter status
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    fetchProductRequests();
    fetchStatuses();
  }, [userId]);

  const fetchProductRequests = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get(`/product-requests/${userId}`, {
        withCredentials: true,
      });
      setRequests(response.data.result);
    } catch (error) {
      console.error("Error fetching product requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatuses = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get(`/product-requests/statuses/${userId}`, {
        withCredentials: true,
      });
      setStatuses(response.data.result);
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  const handleEditClick = (request) => {
    setSelectedRequest(request);
    setNewNotes(request.adminNotes || "");
    setNewStatus(request.status.id);
    setModalOpen(true);
  };

  const handleProductInfoClick = async (productId) => {
    try {
      const response = await api.get(`/product/${productId}`);
      if (response.data && response.data.result) {
        setSelectedProduct(response.data.result); // Set product details
        setPopupVisible(true); // Show the popup
      } else {
        console.error("No product data found.");
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = "auto"; // Reset height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set to scrollHeight
  };

  const handleUpdateRequest = async () => {
    if (selectedRequest) {
      try {
        const requestId = selectedRequest.id;
        try {
          await api.get(`/user/authenticate/${userId}`, {
            withCredentials: true,
          });
        } catch (error) {
          console.error("User authentication failed:", error);
          localStorage.removeItem("userId"); // Reset userId in localStorage
          window.location.href = "/admin"; // Redirect to admin if authentication fails
        }
        await api.put(
          `/product-requests/update-productrequest/${userId}/${requestId}`,
          {
            adminNotes: newNotes,
            statusId: newStatus,
          },
          { withCredentials: true }
        );

        // Update the requests state locally
        setRequests((prev) =>
          prev.map((req) =>
            req.id === selectedRequest.id
              ? {
                  ...req,
                  adminNotes: newNotes,
                  status: { ...req.status, id: newStatus },
                }
              : req
          )
        );
        fetchProductRequests();
        setModalOpen(false);
      } catch (error) {
        console.error("Error updating product request:", error);
      }
    }
  };

  const handleDeleteRequest = async (requestId) => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      await api.delete(
        `/product-requests/delete-productrequest/${userId}/${requestId}`,
        { withCredentials: true }
      );
      setRequests((prev) => prev.filter((req) => req.id !== requestId));
    } catch (error) {
      console.error("Error deleting product request:", error);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredRequests = requests.filter((request) => {
    const matchesSearchTerm =
      request.clientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.clientEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.clientPhoneNumber.includes(searchTerm);

    const matchesStatus = selectedFilterStatus
      ? request.status.statusName === selectedFilterStatus
      : true;

    return matchesSearchTerm && matchesStatus;
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="admin-request-container">
      <h2 className="admin-request-title">Customer Product Requests</h2>
      <div className="filterAndSearch">
        <div className="search-container">
          <span>
            <strong>Search For Product: </strong>
          </span>
          <input
            type="text"
            className="admin-search-bar"
            placeholder="Search by Name, Email, or Phone"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="filter-container">
          <span>
            <strong>Filter By Status: </strong>
          </span>
          <select
            value={selectedFilterStatus}
            className="admin-category-filter"
            onChange={(e) => setSelectedFilterStatus(e.target.value)}>
            <option value="">All Statuses</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.statusName}>
                {status.statusName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className="admin-request-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Product</th>
            <th>Status</th>
            <th>Admin Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((request) => (
            <tr key={request.id}>
              <td>{request.clientName}</td>
              <td>{request.clientEmail}</td>
              <td>{request.clientPhoneNumber}</td>
              <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {request.product.productName}
                  <span
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                    onClick={() => handleProductInfoClick(request.product.id)} // Handle info icon click
                    title="More Info">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                </div>
              </td>
              <td>{request.status.statusName}</td>
              <td>
                <div className="admin-request-notes-container">
                  <div className="admin-request-notes">
                    {request.adminNotes}
                  </div>
                  {request.adminNotes && request.adminNotes.length > 100 && (
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="admin-request-notes-icon"
                      onClick={() => handleEditClick(request)} // Open edit modal
                      title="Edit Notes"
                    />
                  )}
                </div>
              </td>

              <td>
                <button
                  onClick={() => handleEditClick(request)}
                  className="admin-request-edit-button">
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteRequest(request.id)}
                  className="admin-request-delete-button">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Editing Request */}
      {modalOpen && (
        <div className="requests-modal-overlay">
          <div className="requests-modal-content">
            <h2>Edit Request</h2>
            <textarea
              value={newNotes}
              onChange={(e) => {
                setNewNotes(e.target.value);
                handleTextareaInput(e); // Adjust height on input
              }}
              onInput={handleTextareaInput} // Ensure it adjusts on input
              placeholder="Enter admin notes"
            />
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(Number(e.target.value))}>
              {statuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.statusName}
                </option>
              ))}
            </select>

            <div className="requests-modal-actions">
              <button onClick={() => setModalOpen(false)}>Cancel</button>
              <button onClick={handleUpdateRequest}>Save</button>
            </div>
          </div>
        </div>
      )}

      {/* Popup for Product Info */}
      {popupVisible && selectedProduct && (
        <AdminPopup
          isVisible={popupVisible}
          onClose={() => setPopupVisible(false)} // Close the popup
          product={selectedProduct} // Pass the selected product to the popup
        />
      )}
    </div>
  );
};

export default AdminProductRequests;
