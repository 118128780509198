import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/api';
import '../../styles/AddEventForm.css'; // Adjust the path as necessary

const CategoryForm = () => {
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryIcon, setCategoryIcon] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const userId = localStorage.getItem('userId');

  const fileInputRef = useRef(null);

  useEffect(() => {
    
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get("/category", { withCredentials: true });
      const categoryOptions = response.data.result.map((cat) => ({
        value: cat.id,
        label: cat.categoryName,
      }));
      setCategories(categoryOptions);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setErrorMessage("Error fetching categories. Please try again later.");
    }
  };

  const handleAddCategory = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    if (category && !categoryIcon) {
      setErrorMessage('Please upload an SVG icon for the new category.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('categoryName', category);
      if (categoryIcon) {
        formData.append('icon', categoryIcon);
      }

      await api.post(`/category/new-category/${userId}`, formData, { withCredentials: true });
      setSuccessMessage('Category added successfully!');
      setCategory('');
      setCategoryIcon(null);
      fetchCategories();
      fileInputRef.current.value = null;
    } catch (error) {
      console.error("Error adding category:", error);
      setErrorMessage(error.response?.data?.details || 'An unexpected error occurred.');
    }
  };

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      setCategoryIcon(file);
      setErrorMessage('');
    } else {
      setCategoryIcon(null);
      setErrorMessage('Please upload a valid SVG file.');
    }
  };

  return (
    <div className="modify-event-form-container">
      {/* Add Category Form */}
      <form onSubmit={handleAddCategory} className="modify-event-form add-category-form" encType="multipart/form-data">
        <h2 className="modify-event-form-title">Add Category</h2>

        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="New Category Name"
          className="modify-event-input"
        />

        <div className="category-icon-upload">
          <h3>Upload Category Icon (SVG):</h3>
          <input
            type="file"
            accept="image/svg+xml"
            onChange={handleIconChange}
            ref={fileInputRef}
            className="add-event-image-input"
          />
          {categoryIcon && <p>SVG icon selected: {categoryIcon.name}</p>}
        </div>

        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        
        <button type="submit" className="modify-event-submit-button">Add Category</button>
      </form>
    </div>
  );
};

export default CategoryForm;
