import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/api';
import Select from 'react-select';
import '../../styles/AddEventForm.css'; // Adjust the path as necessary

const AddEventForm = () => {
  const [eventName, setEventName] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventSlogan, setEventSlogan] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [images, setImages] = useState([]); // State for multiple image files
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(''); // State for error message
  const userId = localStorage.getItem('userId');
  const fileInputRef = useRef(null);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const response = await api.get('/product');
      const productOptions = response.data.result.map((product) => ({
        value: product.id,
        label: product.productName,
      }));
      setProducts(productOptions); // Adjust based on your API response
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(''); // Reset success message
    setErrorMessage(''); // Reset error message
    if (images.length < 1) {
      setErrorMessage('Please select at least one image');
      return;
    }
    try {
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      const eventResponse = await api.post(`/event/new-event/${userId}`, {
        eventName,
        eventDescription,
        slogan: eventSlogan,
        startDate,
        endDate,
      }, { withCredentials: true });

      const eventId = eventResponse.data.result.id; // Get the newly created event ID
      try {
        await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
      } catch (error) {
        console.error("User authentication failed:", error);
        localStorage.removeItem("userId"); // Reset userId in localStorage
        window.location.href = "/admin"; // Redirect to admin if authentication fails
      }
      // Assign products to the event
      await api.post(`/event/assign-products/${userId}/${eventId}`, {
        productsIds: selectedProducts.map((product) => product.value),
      }, { withCredentials: true });

      // Upload all images
      if (images.length > 0) {
        const formData = new FormData();
        images.forEach((image) => {
          formData.append('images', image);
        });
        try {
          await api.get(`/user/authenticate/${userId}`, { withCredentials: true });
        } catch (error) {
          console.error("User authentication failed:", error);
          localStorage.removeItem("userId"); // Reset userId in localStorage
          window.location.href = "/admin"; // Redirect to admin if authentication fails
        }
        try {
          await api.post(`/event/upload-eventImage/${userId}/${eventId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
          });
        } catch (error) {
          console.error('Error uploading event images:', error);
        }
      }

      // Reset form
      setEventName('');
      setEventDescription('');
      setStartDate('');
      setEndDate('');
      setEventSlogan(''); // Reset slogan state
      setSelectedProducts([]);
      setImages([]); // Reset images state
      fileInputRef.current.value=null;
      setSuccessMessage('Event created successfully!'); // Set success message
    } catch (error) {
      console.error('Error creating event:', error);
      if (error.response && error.response.data && error.response.data.details) {
        setErrorMessage(error.response.data.details);
      } else {
        setErrorMessage('An unexpected error occurred.'); // Fallback error message
      }
    }
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions || []); // Update selected products
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]); // Add new images to the existing array
  };

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index)); // Remove image by index
  };

  return (
    <div className="add-event-form-container">
      <form onSubmit={handleSubmit} className="add-event-form">
        <h2 className="add-event-form-title">Add Event</h2>

        {successMessage && <div className="success-message">{successMessage}</div>} {/* Render success message */}

        <input
          type="text"
          value={eventName}
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Event Name"
          required
          className="add-event-input"
        />
        <textarea
          value={eventDescription}
          onChange={(e) => setEventDescription(e.target.value)}
          placeholder="Event Description"
          required
          className="add-event-textarea"
        />
        <textarea
          value={eventSlogan}
          onChange={(e) => setEventSlogan(e.target.value)}
          placeholder="Event Slogan"
          required
          className="add-event-textarea"
        />
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
          className="add-event-input"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
          className="add-event-input"
        />
        <div className="add-event-product-selection">
          <h3>Select Products:</h3>
          <Select
            isMulti
            options={products}
            value={selectedProducts}
            onChange={handleProductChange}
            placeholder="Select products..."
            className="add-event-product-dropdown"
          />
          <div>
            {selectedProducts.map((product) => (
              <div key={product.value} className="selected-product-item">
                {product.label}
                <button
                  type="button"
                  onClick={() => handleProductChange(selectedProducts.filter((p) => p.value !== product.value))}
                  className="add-event-remove-product-button"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
        <input
          type="file"
          ref={ fileInputRef}
          onChange={handleImageChange}
          accept="image/*" // Limit the file type to images
          multiple // Allow multiple file selection
          className="add-event-image-input"
        />
        <div className="image-previews">
          {images.map((image, index) => (
            <div key={index} className="image-preview">
              <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} className="image-preview-thumbnail" />
              <button
                type="button"
                onClick={() => removeImage(index)}
                className="add-event-remove-image-button"
              >
                X
              </button>
            </div>
          ))}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Render error message */}
        <button type="submit" className="add-event-submit-button add-event-button">Add Event</button>
      </form>
    </div>
  );
};

export default AddEventForm;
