import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/homePage/home";
import ContactUs from "./components/shared/contactUs";
import AboutUs from "./components/shared/aboutUs";
import Shop from "./components/shop/mainShopPage";
import EventShop from "./components/shop/eventShopPage";
import Admin from "./components/admin/adminLogin";
import Dashboard from "./components/admin/sideNavbar";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for Home Page */}
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />

        <Route path="/shop/:categoryId?" element={<Shop />} />
        <Route path="/shop/event/:eventId?" element={<EventShop />} />

        <Route path="/admin" element={<Admin />} />
        <Route path="/admin-dashboard" element={<Dashboard />} />

        {/* Redirect any invalid routes to Home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
