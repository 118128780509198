import React, { useState, useEffect } from 'react';
import { ImageRender } from './ImageRender';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import api from '../../utils/api';
import { Helmet } from 'react-helmet-async';

export default function Carousel({ eventId }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slides, setSlides] = useState([]); // Array to hold slides from the API

    // Fetch event details based on eventId
    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await api.get(`/event/`);
                const eventData = response.data.result;
                // Find the event with the matching eventId
                const event = eventData.find(event => event.id == eventId);
                
                if (event && event.images) {
                    // Map event details to slides
                    const mappedSlides = event.images.map((image, index) => ({
                      id: index, // or use image.id if available
                      url: `${process.env.REACT_APP_IMAGE_URL}${image.imageUrl}`, // Image URL from the event using env variable
                      p: event.slogan, // Slogan as description
                      h1: event.eventName, // Event name as title
                      p2: event.eventDescription // Event description
                    }));
                    setSlides(mappedSlides); // Update slides state
                  } else {
                    console.warn(`Event with id ${eventId} not found.`);
                    setSlides([]); // Clear slides if no event found
                  }
                  
            } catch (error) {
                console.error("Error fetching event details:", error);
            }
        };
    
        fetchEventDetails();
    }, [eventId]); // Re-run when eventId changes
    
        // Automated sliding effect
        useEffect(() => {
            const interval = setInterval(() => {
                goForward();
            }, 3000); // Change slide every 3 seconds
    
            return () => clearInterval(interval); // Cleanup interval on unmount or eventId change
        }, [slides, currentIndex]); // Rerun when slides or currentIndex changes
    
    const goBack = () => {
        const firstSlide = currentIndex === 0;
        const newIndex = firstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goForward = () => {
        const lastSlide = currentIndex === slides.length - 1;
        const newIndex = lastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const containerStyle = {
        color: 'white',
        position: 'relative',
        width: '100%',
        display: 'flex',
    };

    const navigationStyle = {
        position: 'absolute',
        top: '350px', // Adjust this value based on your design
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px', // You can adjust this value
        zIndex: 1,
    };

    const arrowStyle = {
        color: 'black',
        width: '40px',
        height: '40px',
        padding: '8px',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        
    };

    const arrowHoverStyle = {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
    };

    return (
        <>
        <Helmet>
                <meta name="description" content={"Explore amazing events and details with our carousel."} />
                <meta name="keywords" content="events, carousel, images, showcase" />
            </Helmet>
        <div style={containerStyle}>
            {slides.map(slide => {
                if (slide.id === currentIndex) {
                    return <ImageRender key={slide.id} slide={slide} />;
                }
                return null; // Return null when not rendering a slide
            })}
            <div style={navigationStyle}>
                <FaArrowLeft
                    onClick={goBack}
                    style={arrowStyle}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = arrowHoverStyle.backgroundColor)}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '')}
                />
                <FaArrowRight
                    onClick={goForward}
                    style={arrowStyle}
                    onMouseOver={e => (e.currentTarget.style.backgroundColor = arrowHoverStyle.backgroundColor)}
                    onMouseOut={e => (e.currentTarget.style.backgroundColor = '')}
                />
            </div>
        </div>
        </>
    );
}
