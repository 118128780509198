// src/utils/api.js
import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Use an environment variable
  withCredentials: true, // If you need to send cookies with requests
});

// Optionally, you can add interceptors for logging, authentication, etc.
api.interceptors.request.use((config) => {
  // You can modify config before the request is sent
  return config;
}, (error) => {
  // Handle the error
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});

export default api;
