import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons"; // Import location and phone icons
import "../../styles/footer.css"; // Make sure to import your CSS file

const Footer = () => {
  return (
    <div className="main-container">
      <div className="container">
        {/* First Column */}
        <div className="column column1">
          <img src="/assets/logo.svg" alt="Logo" />
          <p>
            Unleash the radiance of your inner beauty with our premium jewelry
            brand - a perfect blend of sophistication and style.
          </p>
          
        </div>

        {/* Fourth Column */}
        <div className="column column4 footer-contact">
          <h4>Contact Us</h4>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
            <a
              href="https://maps.app.goo.gl/RKRjLw7gyerxQ7Tu8"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}>
              11 Gad El-haq, Sheraton Al Matar, El Nozha, Cairo
            </a>
          </p>
          <p style={{ marginTop: "0" }}>
            <FontAwesomeIcon icon={faPhone} className="contact-icon" />
            <a
              href="tel:+201220647111"
              style={{ textDecoration: "none", color: "inherit" }}>
              +201220647111
            </a>
          </p>

          <div className="social-icons">
            <a
              href="https://www.facebook.com/MounirtoEgypt/"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://www.instagram.com/mounirto_jewellery/?hl=en"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://twitter.com/shiekhmounir"
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
        </div>
      </div>
      <div style={{marginTop:"10px"}}>
      <p style={{ fontSize: "12px", textAlign: "center" }}>
            Copyrights © 2024 Mounirto. All Rights Reserved.
          </p>
      </div>
    </div>
  );
};

export default Footer;
