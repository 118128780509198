import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../../styles/newCollection.css"; // Import the styles
import api from "../../utils/api";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO

const NewCollection = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [featuredProducts, setFeaturedProducts] = useState([]); // State to hold featured products
  const [loading, setLoading] = useState(true); // State to handle loading status

  const handleDiscoverMore = () => {
    navigate("/shop"); // Navigate to /shop
  };

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const response = await api.get("/product/featured/"); // Fetch featured products from API

        setFeaturedProducts(response.data.products); // Set featured products in state
      } catch (error) {
        console.error("Error fetching featured products:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };

    fetchFeaturedProducts(); // Call the fetch function
  }, []); // Empty dependency array to run only once on mount
  if (featuredProducts.length < 4) {
    return;
  }
  if (loading) {
    return <div>Loading...</div>; // Loading state while fetching data
  }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover our latest jewelry collection with stunning designs. Shop now and explore the new arrivals!"
        />
      </Helmet>
      <div className="new-collection-container">
        <h2 className="new-collection-title">New Collection</h2>

        <div className="collection-row">
          {/* First Product */}
          <div className="collection-column">
            <div className="large-image">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${featuredProducts[3]?.images[0]?.imageUrl}`}
                alt={featuredProducts[3]?.productName}
              />
            </div>

            <div className="small-image-with-text">
              <div className="text-right">
                <h3>Discover New Arrivals</h3>
                <button
                  className="newCollectbutton"
                  onClick={handleDiscoverMore}>
                  Discover More
                </button>
              </div>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${featuredProducts[1]?.images[0]?.imageUrl}`}
                alt={featuredProducts[1]?.productName}
              />
            </div>
          </div>

          {/* Second Product */}
          <div className="collection-column">
            <div className="small-image-with-text">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${featuredProducts[2]?.images[0]?.imageUrl}`}
                alt={featuredProducts[2]?.productName}
              />

              <div className="text-right">
                <h3>A Jewelry Tells a Great Story</h3>
                <button
                  className="newCollectbutton"
                  onClick={handleDiscoverMore}>
                  Discover More
                </button>
              </div>
            </div>

            <div className="large-image">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}${featuredProducts[0]?.images[0]?.imageUrl}`}
                alt={featuredProducts[0]?.productName}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCollection;
