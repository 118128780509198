import React, { useEffect, useState } from 'react';
import '../../styles/card.css';
import SVG from 'react-inlinesvg';

const Card = ({ path, title, onClick }) => {
  const [loading, setLoading] = useState(true);
  const [iconPath, setIconPath] = useState(null);

  useEffect(() => {
    // If path is provided, set the icon path from the public folder
    if (path) {
      const resolvedPath = `${path}`;
      setIconPath(resolvedPath);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [path]);

  return (
    <div className="card" onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className="icon-container">
        {loading ? (
          <div style={{ margin: 'auto' }}>Loading...</div>
        ) : iconPath ? (
          <SVG src={iconPath} className="categoryIcon" />
        ) : (
          <div style={{ margin: 'auto' }}>Not found</div>
        )}
      </div>
      <div className="card-title">
        <p className="card-title">{title}</p>
      </div>
    </div>
  );
};

export default Card;
