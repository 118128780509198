import React, { useState } from 'react';
import api from '../../utils/api';
import '../../styles/popup.css';

const RequestPriceForm = ({ productId, onClose, onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      clientName: event.target.name.value,
      clientEmail: event.target.email.value,
      clientPhoneNumber: event.target.phone.value,
      message: event.target.message.value,
      productId,
    };

    try {
      setErrorMessage(''); // Clear any previous error message
      console.log(formData);
      const response = await api.post('/product-requests/new-productrequest', formData);

      setSuccessMessage('Form submitted successfully!');
      onSubmit(formData);
      // Optionally clear form inputs after submission
      event.target.reset();
    } catch (error) {
      console.error('Error submitting the form:', error);
      setErrorMessage(
        error.response && error.response.data && error.response.data.details
          ? error.response.data.details
          : 'An error occurred. Please try again later.'
      );
    }
  };

  return (
    <div className="form-popup">
      <span className="close" onClick={onClose}>&times;</span>
      <h2 className="form-heading">Request Price</h2>

    

      <form onSubmit={handleSubmit} className="request-price-form">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" placeholder="Name" required />

        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" placeholder="Email" required />

        <label htmlFor="phone">Phone Number</label>
        <input type="tel" name="phone" id="phone" placeholder="Phone Number" required />

        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" placeholder="Inquiry Message (optional)"></textarea>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default RequestPriceForm;
